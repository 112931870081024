import axios, { AxiosRequestConfig, AxiosResponse, Method } from "axios";

interface RequestOptions {
  endpoint: string;
  method: Method;
  token?: string;
  data?: any;
}

const baseUrl: string = "https://gjcrecycling-f7fa9435192b.herokuapp.com";

export const sendRequestWithAxios = async (options: RequestOptions) => {
  const { endpoint, method, data, token } = options;

  const headers: { [key: string]: string } = {};

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  const config: AxiosRequestConfig = {
    method,
    url: baseUrl + endpoint,
    headers,
    data,
  };

  try {
    const response: AxiosResponse = await axios(config);
    return response;
  } catch (error) {
    // Handle error, you can choose to throw or return an error object here
    throw error;
  }
};

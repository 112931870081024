import "./App.css";
import AppContext from "./context/UserDetails";
import Routes from "./routes";
function App() {
  return (
    <AppContext>
      <Routes />
    </AppContext>
  );
}
 
export default App;

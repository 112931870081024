import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export const ImageComponent: React.FC<{
  src: string;
  width?: string;
  height?: string;
  _style?: string;
}> = ({ src, _style }) => {
  return (
    <LazyLoadImage
      className={`${_style}`}
      effect="blur"
      src={src}
      useIntersectionObserver={true}
    />
  );
};

import React, { createContext, useState, PropsWithChildren, useEffect } from 'react';
import { getAllItems, getAllLocations, getAllUsers } from '../api/handlers';
import Items from '../dashboard/items';

export type UserInfo = {
  id?: string;
  fullName?: string;
  email?: string;
  phone?: string;
  accessToken?: string;
  userId?: number;
  roles?: string;
  photo?: string;
  status?: boolean;
  loggedIn?: boolean;
};

export type Items = {
  id?: string;
  name?: string;
  photo?: string;
};

export type Booking = {
  id: string;
  user: string;
  items: Array<string>;
  additionalPhone: string;
  pickUpAddress: string;
  pickUpLocation: string;
  pickUpDate: string;
  pickUpTime: string;
  bookingDate: string;
  status: string;
  bookingId: number;
};

export type Location = {
  id: string;
  name: string;
};
export type valueProps = {
    [key: string]: string;
  };
  
interface ContextValue {
  user: UserInfo | null;
  setUser: (user: UserInfo | null) => void;
  items: Array<Items>;
  setItems:(items: Array<Items>) => void
  locations:Array<Location>;
  setAllLocations: (locations: Array<Location>) => void;
  selectedLocation:Location | null;
  setSelectedLocation: (selectedLocation: Location) => void;
  selectedItems: Array<Items>;
  setSelectedItems: (selectedItems: Array<Items>) => void;
  selectedItem: Items | null;
  setSelectedItem: (selectedItem: Items) => void;
  selectedBooking: Booking | null;
  setSelectedBooking: (selectedBooking: Booking) => void;
  selectedUser: UserInfo | null;
  setSelectedUser: (selectedUser: UserInfo) => void;
  values: valueProps;
  setValues:  React.Dispatch<React.SetStateAction<valueProps>>;
  allUsers: Array<UserInfo>;
}

export function getUser(): UserInfo{
  const user : UserInfo = JSON.parse(localStorage?.getItem('user') || "{}") as UserInfo ;
  if( !user?.id || !user?.accessToken || !user?.fullName || !user?.photo || !user?.roles){
    localStorage.removeItem('user');
    return {
      loggedIn : false
    }
  }else if (user.roles == 'User' ){
    localStorage.removeItem('user');
    return {
      loggedIn : false
    }
  }else{
    return {...user,loggedIn: true}
  }
}
export const Context = createContext<ContextValue>({
  user: null,
  setUser: () => {} ,
  items: [],
  setItems:() => {} ,
  locations:[],
  setAllLocations: () => {},
  selectedLocation:null,
  setSelectedLocation: () => {},
  selectedItems : [],
  setSelectedItems: () => {},
  values : {},
  setValues : () =>{},
  allUsers : [],
  setSelectedItem : () =>{},
  selectedItem : null,
  setSelectedBooking : () =>{},
  selectedUser : null,
  setSelectedUser : () =>{},
  selectedBooking : null
});

const AppContext = ({ children }: PropsWithChildren<{}>) => {
  const [allUsers, setAllUsers] = useState<Array<UserInfo>>([]);
  const [user, setUser] = useState<UserInfo | null>(getUser());
  const [items, setItems] = useState<Array<Items>>([]);
  const [locations, setAllLocations] = useState<Array<Location>>([]);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
  const [selectedItems, setSelectedItems] = useState<Array<Items>>([]);
  const [selectedItem, setSelectedItem] =useState<Items | null>(null);
  const [selectedBooking, setSelectedBooking] =useState<Booking | null>(null);
  const [selectedUser, setSelectedUser] = useState<UserInfo | null>(null);
  const bookingValues = {
    firstName: user?.fullName as string,
    email: user?.email as string,
    phone: '',
    phone2: "",
    address: "",
    pickUpDate: "",
    pickUpTimeOne: '',
    pickUpTimeTwo: '',
    location : '',
    bookingId : '',
  };
  const [values, setValues] = useState<valueProps>(bookingValues);


  useEffect(()=>{
    async function getAcceptableItems (){
      try {
        const allItems = await getAllItems();      
        setItems(allItems.data);
      } catch (error) {
        
      }
      
    }
    getAcceptableItems();
  },[])

  useEffect(()=>{
    async function getLocations (){
      try {
        const allItems = await getAllLocations(user?.accessToken as string);      
        setAllLocations(allItems.data);
      } catch (error) {
        
      }
    }
    getLocations();
  },[])

  useEffect(()=>{
    async function getUsers(){
      if(!user?.loggedIn) return false
      try {
        const allUsers = await getAllUsers(user?.accessToken as string);      
        setAllUsers(allUsers.data);
      } catch (error) {
        
      }
    }
    getUsers();
  },[user?.accessToken])

  const value: ContextValue = {
    user,
    setUser,
    items,
    setItems,
    locations,
    setAllLocations,
    selectedLocation,
    setSelectedLocation,
    selectedItems,
    setSelectedItems,
    values,
    setValues,
    allUsers,
    selectedItem,
    setSelectedItem,
    selectedBooking,
    setSelectedBooking,
    selectedUser,
    setSelectedUser
  };

  

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};

export default AppContext;

import React, { useState, useContext, useEffect } from "react";
import SubLayout from "../../common/subLayout";
import { InputComponent } from "../../components/Tags/input";
import { ButtonComponent } from "../../components/Tags/button";

import { useModal } from "../../context/modal";

import { values } from "../../types";
import { Context } from "../../context/UserDetails";
import { ButtonType } from "../../data";
import { ResponseBody, createAcceptableItem, createCoverArea, deleteCoverArea, modifyCoverArea } from "../../api/handlers";
const Area: React.FC<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ open, setOpen }): JSX.Element => {
  const [values, setValues] = useState<values>({ search: "" });
  const contextValue = useContext(Context);
  const { user,locations,setAllLocations,selectedLocation  } = contextValue;
  const { setModalToggle, initialState } = useModal();
  const [name, setName] = useState('');
  const [loading, setLoading]= useState<boolean>(false);

  useEffect(()=>{
    setName(selectedLocation?.name as string);
  },[selectedLocation])
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setModalToggle({
      ...initialState,
      doneOpen: true,
      successText: "Location Successfully Added",
    });
  };
  const handleClick = async () =>{
    try { 
      setLoading(true);
      const response : ResponseBody= await modifyCoverArea(user?.accessToken as string, {name, locationId:selectedLocation?.id });
      setLoading(false);
      if(response.status == 201){
        setAllLocations([...locations.filter(i => i.id != selectedLocation?.id),response.data]);
        setModalToggle({
          ...initialState,
          doneOpen: true,
          successText: "Location Successfully Edited",
        });
      }
    } catch (error) {
      setLoading(false);
    }
  }
  const handleDelete = async () =>{
    try { 
      setLoading(true);
      const response : ResponseBody= await deleteCoverArea(user?.accessToken as string, {locationId:selectedLocation?.id });
      setLoading(false);
      if(response.status == 201){
        setAllLocations([...locations.filter(i => i.id != selectedLocation?.id)]);
        setModalToggle({
          ...initialState,
          doneOpen: true,
          successText: "Location Successfully Deleted",
        });
      }
    } catch (error) {
      setLoading(false);
    }
  }
  return (
    <SubLayout title="Add New Location" open={open} setOpen={setOpen}>
      <form className="my-5 ">
        <div className="flex my-3 flex-col">
          <label className="pb-3 text-sm" htmlFor=''>
            Enter Location
          </label>
          <input
            className={`w-full border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2] text-sm outline-none `}
            type='text'
            value={name}
            onChange={(e)=> setName(e.currentTarget.value)}
          />
        </div>
        <div className="my-20 flex justify-between items-center gap-5">
          <button
            type="button"
            className={`${ButtonType['light']} font-light text-base text-light font-gsans-light px-5 py-2 rounded-md px-5 px-5 border-[1px] w-36 border-primary`}
            onClick={(e)=>{
              e.preventDefault();
              if(!loading ) handleDelete();
            }}
          >
            {loading ? "Loading" : 'Delete'}
          </button>
          <button
            type="button"
            className={`${loading ? ButtonType['light'] : ButtonType['dark']} font-light text-base text-light font-gsans-light px-5 py-2 rounded-md px-5`}
            onClick={(e)=>{
              e.preventDefault();
              if(!loading && name ) handleClick();
            }}
          >
            {loading ? "Loading" : 'Save Edit'}
          </button>
        </div>
      </form>
    </SubLayout>
  );
};

export default Area;

import Layout from "../common/layout";
import { OverviewComponent } from "../components/overview";
import Charts from "./charts";
import bg from "../assets/dashboardBg.png";
import { useEffect, useState, useContext } from "react";
import { Context, UserInfo, getUser } from "../context/UserDetails";
import { useNavigate, useParams } from "react-router";
import { ResponseBody, verifyEmail } from "../api/handlers";
import { useModal } from "../context/modal";
const VerifyEmail = (): JSX.Element => {
  const navigate = useNavigate();
  const contextValue = useContext(Context);
  const {token} = useParams();
  const { user } = contextValue;
  const { initialState, setModalToggle } = useModal();


  useEffect(()=>{
    async function verifyUser() {
      if(!token) return false
      try {
        const response : ResponseBody = await verifyEmail(token as string);
        console.log(response);
        
        if(response.status == 201 || response.status == 200){
          setModalToggle({ ...initialState,  doneOpen: true, detailsModal: true, successText : "Account verified successfully" });
        }else{
            navigate('/login');
        }
      } catch (error) {
        console.log(error);
      }
    }
    verifyUser();
  }, [token]);
  
  return (
    <>
    <Layout text="Dashboard" user={user as UserInfo}>
      <main className="">
        <div
          style={{ backgroundImage: `url(${bg})` }}
          className="rounded-3xl p-5"
        >
          <h1 className="text-primary  font-semibold text-2xl">
            Hey welcome Back, {user?.fullName}!👋
          </h1>
          <p className="font-light font-gsans-light text-primary md:text-xl text-base my-4">
            Welcome back to your{" "}
            <strong className="font-bold font-gsans-medium">
              {" "}
              GJC Recycling dashboard!
            </strong>{" "}
            I'm excited to help you monitor your user's bookings and give you
            the over view of what is going on with your company.
          </p>
        </div>
        <h1 className="text-dark text-xl my-8 font-bold">
          Overview of this Month
        </h1>
        <OverviewComponent />
        <Charts />
      </main>
    </Layout>
    </>
  );
};

export default VerifyEmail;

import React, { useState, useContext } from "react";
import SubLayout from "../../common/subLayout";
import { InputComponent } from "../../components/Tags/input";
import { ButtonComponent } from "../../components/Tags/button";

import { useModal } from "../../context/modal";

import { values } from "../../types";
import { Context } from "../../context/UserDetails";
import { ButtonType } from "../../data";
import { ResponseBody, createAcceptableItem, createCoverArea } from "../../api/handlers";
const Area: React.FC<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ open, setOpen }): JSX.Element => {
  const [values, setValues] = useState<values>({ search: "" });
  const contextValue = useContext(Context);
  const { user,locations,setAllLocations  } = contextValue;
  const { setModalToggle, initialState } = useModal();
  const [name, setName] = useState('');
  const [loading, setLoading]= useState<boolean>(false);
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setModalToggle({
      ...initialState,
      doneOpen: true,
      successText: "Location Successfully Added",
    });
  };
  const handleClick = async () =>{
    try { 
      setLoading(true);
      const response : ResponseBody= await createCoverArea(user?.accessToken as string, {name});
      setLoading(false);
      if(response.status == 201){
        setAllLocations([...locations,response.data]);
        setModalToggle({
          ...initialState,
          doneOpen: true,
          successText: "Location Successfully Added",
        });
      }
    } catch (error) {
      setLoading(false);
    }

  }
  return (
    <SubLayout title="Add New Location" open={open} setOpen={setOpen}>
      <form className="my-5 ">
        <div className="flex my-3 flex-col">
          <label className="pb-3 text-sm" htmlFor=''>
            Enter Location
          </label>
          <input
            className={`w-full border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2] text-sm outline-none `}
            type='text'
            value={name}
            onChange={(e)=> setName(e.currentTarget.value)}
          />
        </div>
        <div className="my-20 flex justify-center">
          <button
            type="button"
            className={`${loading ? ButtonType['light'] : ButtonType['dark']} font-light text-base text-light font-gsans-light px-5 py-2 rounded-md px-5`}
            onClick={(e)=>{
              e.preventDefault();
              if(!loading && name ) handleClick();
            }}
          >
            {loading ? "Loading" : 'Add Location'}
          </button>
        </div>
      </form>
    </SubLayout>
  );
};

export default Area;

import { PropsWithChildren, useState } from "react";
import Layout from "./layout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ButtonComponent } from "../components/Tags/button";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { getUser } from "../context/UserDetails";
import { ButtonType } from "../data";
import { resetPassword } from "../api/handlers";
const PasswordReset = (): JSX.Element => {
  const { token } = useParams();
  const [loggedIn, setLoggedIn] = useState<Boolean>(
    getUser().loggedIn || false
  );
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (loggedIn) {
      navigate("/dashboard");
    }
  }, []);

  function isStrongPassword(password: string) {
    const minLength = 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+[\]{};':"\\|,.<>?/]/.test(password);

    return (
      password.length >= minLength &&
      hasUppercase &&
      hasLowercase &&
      hasNumber &&
      hasSpecialChar
    );
  }

  const [heading, setHeading] = useState({
    title: "Forgot Password",
    text: "Enter the email you used to register on the system",
    subTitile: "",
  });

  const SwiperNextButton = ({ children }: PropsWithChildren) => {
    const swiper = useSwiper();

    const RequestToken = () => {
      setHeading({ ...heading, subTitile: "Create Passowrd" });
      swiper.slideNext();
    };

    return (
      <ButtonComponent
        clickHandler={RequestToken}
        _style="py-4"
        type="dark"
        title="Reset Password"
      />
    );
  };

  const SwiperPrevButton = ({ children }: PropsWithChildren) => {
    const swiper = useSwiper();

    const BackToToken = () => {
      setHeading({ ...heading, subTitile: "Create Password" });
      swiper.slidePrev();
    };

    return (
      <ButtonComponent clickHandler={BackToToken} _style="py-4" title="Back" />
    );
  };

  const swiperProps = {
    autoHeight: false,
    allowTouchMove: false,
    slidesPerView: 1,
    spaceBetween: 20,
  };
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await resetPassword(token as string, {
        newPassword: password,
      });
      setLoading(false);
      if (response.status == 201) {
        toast.success(response.message);
        setTimeout(() => navigate("login"), 1000);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      {!loggedIn && (
        <Layout
          subTitle={heading.subTitile}
          title={heading.title}
          text={heading.text}
        >
          <Swiper className="max-w-[95vw] md:max-w-[35vw]" {...swiperProps}>
            <SwiperSlide>
              <div className="">
                <div className="flex my-3 flex-col">
                  <label className="pb-3 text-sm" htmlFor="password">
                    Create Password
                  </label>
                  <input
                    className={`  border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2] text-sm outline-none p-2 shadow-md`}
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.currentTarget.value)}
                  />
                </div>
                <div className="flex my-3 flex-col">
                  <label className="pb-3 text-sm" htmlFor="password">
                    Confirm Password
                  </label>
                  <input
                    className={`  border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2] text-sm outline-none p-2 shadow-md`}
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                  />
                </div>
                <div className="my-5 gap-5 flex flex-col">
                  <button
                    className={`${loading && "not-allowed"} ${
                      ButtonType[loading ? "light" : "dark"]
                    } font-light text-base text-light font-gsans-light px-5 py-2 rounded-md py-4`}
                    onClick={(e) => {
                      e.preventDefault();
                      if (password != confirmPassword) {
                        toast.error("Passwords do not match");
                        setConfirmPassword("");
                        setPassword("");
                      } else if (!isStrongPassword(password)) {
                        toast.error(
                          "Password must be at least 8 characters long, contain at least one number, one uppercase,one lowercase letter and one special character"
                        );
                        setConfirmPassword("");
                        setPassword("");
                      } else {
                        !loading && handleSubmit();
                      }
                    }}
                  >
                    {loading ? "Loading..." : "Create Password"}
                  </button>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </Layout>
      )}
      <ToastContainer />
    </>
  );
};

export default PasswordReset;

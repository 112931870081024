import Layout from "./layout";
import { InputComponent } from "../components/Tags/input";
import { ButtonComponent } from "../components/Tags/button";

import { useState, useEffect, useContext } from "react";

import { values } from "../types";

import { RegisterValues } from "../data";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { ResponseBody, loginHandler } from "../api/handlers";
import { Context, getUser } from "../context/UserDetails";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = (): JSX.Element => {
  const [values, setValues] = useState<values>(RegisterValues);
  const contextValue = useContext(Context);
  const { user, setUser } = contextValue;
  const [loading, setLoading] = useState<Boolean>(false);
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState<Boolean>(
    getUser().loggedIn || false
  );
  useEffect(() => {
    if (loggedIn) {
      navigate("/dashboard");
    }
  }, []);
  // useEffect(()=>{
  //   console.log(values);

  // })
  const submitLogin = async (email: string, password: string) => {
    if (!values.email || !values.password)
      return toast.error("Enter required fields!");
    try {
      setLoading(true);
      const response: ResponseBody = await loginHandler(email, password);
      setLoading(false);
      if (response.status == 201) {
        if (response.data.roles == "User") {
          toast.error("Incorrect email or password");
          return false;
        }
        localStorage.setItem("user", JSON.stringify(response?.data));
        setUser(response?.data);
        navigate("/dashboard", { replace: true });
      } else {
        toast.error(response.message);
        setValues({ password: "" });
        console.log(response.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <>
      {!loggedIn && (
        <>
          <Layout
            title="Welcome back !"
            text="Please enter your username and password"
          >
            <div className="">
              <InputComponent
                _style="  border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2]"
                values={values}
                setValues={setValues}
                name="email"
                label="Email"
                type="email"
                placeholder="gjc@gmail.com"
              />

              <InputComponent
                _style="  border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2]"
                values={values}
                setValues={setValues}
                name="password"
                label="Password"
                type="password"
                placeholder="*******"
              />
              <div className="flex justify-end">
                <NavLink
                  className="text-red-700 self-end text-end "
                  to="/reset"
                >
                  Forgotten Password?
                </NavLink>
              </div>
              <div className="my-5 gap-5 flex flex-col">
                <ButtonComponent
                  clickHandler={async () =>
                    !loading && submitLogin(values.email, values.password)
                  }
                  _style={`py-4 my-2 ${loading && "not-allowed"}`}
                  type={loading ? "light" : "dark"}
                  title={loading ? "Loading..." : "Sign In"}
                />
              </div>
            </div>
          </Layout>
          <ToastContainer />
        </>
      )}
    </>
  );
};

export default Login;

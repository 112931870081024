import { NavLink, useLocation } from "react-router-dom";
import { LightLogoComponent } from "./logo";
import { SidebarLinks } from "../data";
import { useState, useContext } from "react";
import { GoHomeFill, GoPersonFill } from "react-icons/go";
import { BsPeopleFill } from "react-icons/bs";
import { MdOutlineLogout, MdOutlineSettings } from "react-icons/md";
import { IoSettingsOutline, IoLocationOutline } from "react-icons/io5";

import item1 from "../assets/item1.png";
import item2 from "../assets/item2.png";
import book1 from "../assets/book1.png";
import book2 from "../assets/book2.png";
import { Context } from "../context/UserDetails";

const Links = () => {
  const path = useLocation().pathname;
  const contextValue = useContext(Context);
  const { user } = contextValue;
  const icons = [
    <GoHomeFill />,
    <img
      className="w-4"
      src={path === "/dashboard/bookings" ? book2 : book1}
      alt="booking icon"
    />,
    <BsPeopleFill />,
    <img
      className="w-4"
      src={path === "/dashboard/items" ? item1 : item2}
      alt="item icon"
    />,
    <IoLocationOutline />,

    <MdOutlineSettings />,
    <GoPersonFill />,
    <MdOutlineLogout />,
  ];

  const linkState = {
    active: "text-primary bg-white",
    inactive: "textw-white",
  };

  const renderLinks = SidebarLinks.map((data, index) => {
    return (
      <div
        onClick={(e) => {
          if (data.path == "/login") {
            e.preventDefault();
            localStorage.removeItem("user");
          }
        }}
        className={`${
          data.path == "/dashboard/admins" &&
          user?.roles?.toLowerCase() !== "superadmin" &&
          "hide"
        }`}
      >
        <NavLink
          key={data.name}
          className={`text-sm flex items-center gap-3 p-3 w-full rounded-s-xl ${
            data.path == path ? linkState.active : linkState.inactive
          } `}
          to={data.path}
        >
          {icons[index]} <li className="list-none ">{data.name}</li>
        </NavLink>
      </div>
    );
  });

  return <nav className="flex flex-col  my-5 gap-5 ">{renderLinks}</nav>;
};

const Sidebar = (): JSX.Element => {
  return (
    <section className=" pl-4 my-10  text-white">
      <div className="bg-white  w-fit h-fit rounded-full mx-auto">
        <LightLogoComponent />
      </div>
      <Links />
    </section>
  );
};

export const MobileSidebar = () => {
  return (
    <section>
      <Links />
    </section>
  );
};

export default Sidebar;

import axios, { AxiosResponse, Method } from "axios";
import { AxiosError } from "axios";
import { sendRequestWithAxios } from "./sendDataWithAxios";
import { Booking, Items, UserInfo } from "../context/UserDetails";

export interface ResponseBody {
  status?: number;
  data?: any;
  type?: string;
  message?: any;
}


export async function loginHandler(email: string, password: string): Promise<ResponseBody> {
  const method: Method = "POST";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: '/auth/login',
      method,
      data: { email, password }
    });
    let user : UserInfo ={

    };
    if (response.status == 201) {
        user = {
            id : response.data.data.id,
            fullName : response.data.data.fullName,
            email : response.data.data.email,
            phone : response.data.data.phone,
            accessToken : response.data.data.accessToken,
            userId : response.data.data.userId,
            roles : response.data.data.roles,
            photo : response.data.data.photo,
            status : response.data.data.status,
        }

    }
    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: user,
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}


export async function registerHandler(email: string, password: string, phone: string, fullName: string): Promise<ResponseBody> {
  const method: Method = "POST";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: '/auth/register',
      method,
      data: { email, password, phone, fullName }
    });
    let user : UserInfo = {

    };
    if (response.status == 201) {
        user = {
            id : response.data.data.id,
            fullName : response.data.data.fullName,
            email : response.data.data.email,
            phone : response.data.data.phone,
            accessToken : response.data.data.accessToken,
            userId : response.data.data.userId,
            roles : response.data.data.roles,
            photo : response.data.data.photo,
            status : response.data.data.status,
        }

    }
    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: user,
    };

    return res;
  } catch (error: any) {
    console.log(error);
    
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}

export async function createNewUser(token: string, data : any): Promise<ResponseBody> {
  
  const method: Method = "POST";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: '/admin/users/create',
      method,
      data,
      token
    });
    let user : UserInfo = {

    };
    if (response.status == 201) {
        user = {
            id : response.data.data.id,
            fullName : response.data.data.fullName,
            email : response.data.data.email,
            phone : response.data.data.phone,
            accessToken : response.data.data.accessToken,
            userId : response.data.data.userId,
            roles : response.data.data.roles,
            photo : response.data.data.photo,
            status : response.data.data.status,
        }

    }
    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: user,
    };

    return res;
  } catch (error: any) {
    console.log(error);
    
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}

export async function getDashboardStats(token: string): Promise<ResponseBody> {
  const method: Method = "GET";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: '/booking/getBookingStats',
      method,
      token
    });
    let res : ResponseBody = {};
    if (response.status == 201) {
      res= {
        status: response.status,
        message: response.data.message,
        data: response.data.data,
      };
    }

    return res;
  } catch (error: any) {
    console.log(error);
    
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}

export async function getGrowthStats(token: string): Promise<ResponseBody> {
  const method: Method = "GET";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: '/booking/getGrowthStats',
      method,
      token
    });
    let res : ResponseBody = {};
    if (response.status == 201) {
      res= {
        status: response.status,
        message: response.data.message,
        data: response.data.data,
      };
    }

    return res;
  } catch (error: any) {
    console.log(error);
    
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}

export async function getCurrentMonthBookingStats(token: string): Promise<ResponseBody> {
  const method: Method = "GET";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: '/booking/getCurrentMonthBookingStats',
      method,
      token
    });
    let res : ResponseBody = {};
    if (response.status == 201) {
      res= {
        status: response.status,
        message: response.data.message,
        data: response.data.data,
      };
    }

    return res;
  } catch (error: any) {
    console.log(error);
    
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}


export async function getUserMonthlyBookingOverview(token: string): Promise<ResponseBody> {
  const method: Method = "GET";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: '/booking/getUserMonthlyBookingOverview',
      method,
      token
    });
    let res : ResponseBody = {};
    if (response.status == 201) {
      res= {
        status: response.status,
        message: response.data.message,
        data: response.data.data,
      };
    }

    return res;
  } catch (error: any) {
    console.log(error);
    
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}

export async function modifyInfo(FormData : FormData | undefined, token : string, data : any = {}  ): Promise<ResponseBody> {
  const method: Method = "POST";
  
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: '/users/modifyUserInfo',
      method,
      data : FormData || data,
      token
    });
    
    let user : UserInfo ={

    };
    if (response.status == 201) {
        user = {
            id : response.data.data.id,
            fullName : response.data.data.fullName,
            email : response.data.data.email,
            phone : response.data.data.phone,
            accessToken : response.data.data.accessToken,
            userId : response.data.data.userId,
            roles : response.data.data.roles,
            photo : response.data.data.photo,
            status : response.data.data.status,
        }

    }
    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: user,
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}

export async function modifyUserPassword(token : string, data : any  ): Promise<ResponseBody> {
  const method: Method = "POST";
  console.log(data);
  
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: '/auth/changePassword',
      method,
      data,
      token
    });
    console.log(response);
    
    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
    };
    return res;
  } catch (error: any) {
    console.log(error);
    
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}

export async function getAllItems(): Promise<ResponseBody> {
  const method: Method = "GET";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: '/items/getAllItems',
      method,
    });
    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: response.data.data,
    };

    return res;
  } catch (error: any) {
    console.log(error);
    
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}

export async function getAllBookings(token: string): Promise<ResponseBody> {
  const method: Method = "GET";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: '/admin/booking/getAllBookings',
      method,
      token
    });
    let bookings : Array<Booking> =[];
    if (response.status == 201) {
      bookings = response.data.data;
    }
    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: bookings,
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}
export async function getNewBookings(token: string): Promise<ResponseBody> {
  const method: Method = "GET";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: '/admin/booking/getAllNewBookings',
      method,
      token
    });
    let bookings : Array<Booking> =[];
    if (response.status == 201) {
      bookings = response.data.data;
    }
    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: bookings,
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}

export async function getAllLocations(token: string): Promise<ResponseBody> {
  const method: Method = "GET";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: '/locations/getAllLocations',
      method,
      token
    });
    let locations : Array<Location> =[];
    if (response.status == 201) {
      locations = response.data.data;
    }
    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: locations,
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}
export async function createNewBooking(token: string, data: any): Promise<ResponseBody> {
  const method: Method = "POST";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: '/booking/create',
      method,
      data,
      token
    });
    
    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: response.data.data
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}

export async function getUserInfo(token: string, userId: string): Promise<ResponseBody> {
  const method: Method = "GET";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: `/admin/users/getUser?userId=${userId}`,
      method,
      token
    });
    let user : UserInfo ={

    };
    if (response.status == 201) {
        user = {
            id : response.data.data.id,
            fullName : response.data.data.fullName,
            email : response.data.data.email,
            phone : response.data.data.phone,
            userId : response.data.data.userId,
            roles : response.data.data.roles,
            photo : response.data.data.photo,
            status : response.data.data.status,
        }

    }
    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: user,
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}

export async function getAllUsers(token: string): Promise<ResponseBody> {
  const method: Method = "GET";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: "/admin/users/getAllUsers",
      method,
      token
    });
    let users : Array<UserInfo> =[];
    if (response.status == 201) {
      users = response.data.data
    }
    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: users,
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}

export async function getEveryUser(token: string): Promise<ResponseBody> {
  const method: Method = "GET";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: "/admin/users/getEveryUser",
      method,
      token
    });
    let users : Array<UserInfo> =[];
    if (response.status == 201) {
      users = response.data.data
    }
    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: users,
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}

export async function getAllUserBookingStats(token: string): Promise<ResponseBody> {
  const method: Method = "GET";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: "/admin/booking/getAllUserBookingStats",
      method,
      token
    });

    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: response.data.data,
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}

export async function getAllUserBookingGrowthStats(token: string): Promise<ResponseBody> {
  const method: Method = "GET";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: "/admin/booking/getUsersBookingGrowthStats",
      method,
      token
    });

    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: response.data.data,
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}

export async function getAllUserStats(token: string): Promise<ResponseBody> {
  const method: Method = "GET";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: "/admin/users/getAllUserStats",
      method,
      token
    });

    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: response.data.data,
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}

export async function getAllUserGrowthStats(token: string): Promise<ResponseBody> {
  const method: Method = "GET";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: "/admin/users/getAllUserGrowthStats",
      method,
      token
    });

    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: response.data.data,
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}

export async function getBookingOverview(token: string): Promise<ResponseBody> {
  const method: Method = "GET";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: "/admin/booking/getBookingOverview",
      method,
      token
    });

    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: response.data.data,
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}


export async function getWeeklyBooks(token: string): Promise<ResponseBody> {
  const method: Method = "GET";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: "/admin/booking/getWeekBookingStats",
      method,
      token
    });

    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: response.data.data,
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}


export async function getUserBookingStat(token: string, userId : string): Promise<ResponseBody> {
  const method: Method = "GET";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: `/admin/booking/getUserBookingStats?userId=${userId}`,
      method,
      token
    });

    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: response.data.data,
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}

export async function getTopUsers(token: string): Promise<ResponseBody> {
  const method: Method = "GET";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: `/admin/users/getTopUsers`,
      method,
      token
    });

    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: response.data.data,
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}

export async function createAcceptableItem(token: string, form : FormData): Promise<ResponseBody> {
  const method: Method = "POST";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: `/admin/items/create`,
      method,
      token,
      data : form
    });

    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: response.data.data,
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}

export async function modifyAcceptableItem(token: string, form : FormData): Promise<ResponseBody> {
  const method: Method = "POST";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: `/admin/items/modify`,
      method,
      token,
      data : form
    });

    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: response.data.data,
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}

export async function createCoverArea(token: string, data : any): Promise<ResponseBody> {
  const method: Method = "POST";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: `/admin/locations/create`,
      method,
      token,
      data 
    });

    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: response.data.data,
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}

export async function modifyCoverArea(token: string, data : any): Promise<ResponseBody> {
  const method: Method = "POST";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: `/admin/locations/modify`,
      method,
      token,
      data 
    });

    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: response.data.data,
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}

export async function deleteCoverArea(token: string, data : any): Promise<ResponseBody> {
  const method: Method = "POST";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: `/admin/locations/delete`,
      method,
      token,
      data 
    });

    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: response.data.data,
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}

export async function suspendUser(token: string, data : any): Promise<ResponseBody> {
  const method: Method = "POST";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: `/admin/users/suspend`,
      method,
      token,
      data 
    });

    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: response.data.data,
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}
export async function activateUser(token: string, data : any): Promise<ResponseBody> {
  const method: Method = "POST";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: `/admin/users/activate`,
      method,
      token,
      data 
    });

    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: response.data.data,
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}
export async function deleteUser(token: string, data : any): Promise<ResponseBody> {
  const method: Method = "POST";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: `/admin/users/delete`,
      method,
      token,
      data 
    });

    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: response.data.data,
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}
export async function requestResetToken(data: any): Promise<ResponseBody> {
  const method: Method = "POST";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: '/auth/requestResetPassword',
      method,
      data,
    });
    
    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: response.data.data
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}

export async function resetPassword(token : string,data: any): Promise<ResponseBody> {
  const method: Method = "POST";
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: '/auth/resetPassword',
      method,
      data,
      token,
    });
    
    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: response.data.data
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}

export async function updateBookingStatus(token : string,data: any): Promise<ResponseBody> {
  const method: Method = "POST";
  
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: '/admin/booking/setBookingStatus',
      method,
      data,
      token,
    });
    
    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: response.data.data
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}

export async function verifyEmail(token : string): Promise<ResponseBody> {
  const method: Method = "POST";
  
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: `/auth/verifyEmail`,
      method,
      token,
    });
    
    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: response.data.data
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}
export async function getUserBookingOverview(token : string,userId: string): Promise<ResponseBody> {
  const method: Method = "GET";
  
  try {
    const response: AxiosResponse = await sendRequestWithAxios({
      endpoint: `/admin/booking/getUserBookingOverview?userId=${userId}`,
      method,
      token,
    });
    
    let res: ResponseBody = {
      status: response.status,
      message: response.data.message,
      data: response.data.data
    };

    return res;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error 
      return {
        status: error.response?.status || 500,
        message: error.response?.data?.message || error.response?.data?.error || "An error occurred",
      };
    }

    // Handle other non-Axios errors here
    return {
      status: 500,
      message: "An unknown error occurred",
    };
  }
}
import Layout from "../../common/layout";
import { Context, UserInfo, getUser } from "../../context/UserDetails";
import { useModal } from "../../context/modal";
import { CoverAreaDatas } from "../../data";
import { values } from "../../types";
import { useContext, useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router";

const Areas = (): JSX.Element => {
  const { setModalToggle, initialState } = useModal();
  const contextValue = useContext(Context);
  const { user,locations, setSelectedLocation  } = contextValue;
  const [loggedIn, setLoggedIn] = useState<Boolean>(getUser().loggedIn || false);
  const navigate = useNavigate();
  const EditData = (data: string) => {
    setModalToggle({ ...initialState, editArea: true });
  };

  useEffect(()=>{
    if (!loggedIn) {
      navigate('/login');
    }
  })

  return (
    <>
      {loggedIn && <Layout showSearch={true} type="Area" text="Cover Area" user={user as UserInfo}>
        <section className="max-w-4xl mx-auto grid lg:grid-cols-3 grid-cols-2 gap-5">
          {locations?.map((data, index) => {
            return (
              <div
                onClick={() => {
                  setSelectedLocation(data)
                  EditData(data.id)}
                }
                key={index}
                className="bg-secondary cursor-pointer  rounded-md text-center py-3"
              >
                {data.name}
              </div>
            );
          })}
        </section>
      </Layout>}
    </>
  );
};

export default Areas;

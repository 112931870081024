import React, { useContext } from "react";
import { Booking, Context } from "../context/UserDetails";

import { useModal } from "../context/modal";

interface MyComponentProps {
  data: Booking;
}
const statusColor: {
  [key: string]: string;
} = {
  pending: "text-blue-600",
  successful: "text-primary",
  cancelled: "text-red-600",
};

const List: React.FC<MyComponentProps> = ({ data }) => {
  const contextValue = useContext(Context);
  const { locations, allUsers, setSelectedBooking } = contextValue;

  const { setModalToggle, initialState } = useModal();

  return (
    <tr
      onClick={() => {
        setSelectedBooking(data);
        setModalToggle({ ...initialState, detailsModal: true });
      }}
      className="text-center cursor-pointer border-b p-2 "
    >
      <td className="p-2 md:p-3 font-gsans-light font-medium">
        {data.bookingId}
      </td>
      <td className="p-2 md:p-3 font-gsans-light font-medium">
        {data.bookingDate.substring(0, 10)}
      </td>
      <td className="p-2 md:p-3 font-gsans-light font-medium">
        {allUsers?.filter((i) => i.id == data.user)[0]?.fullName ||
          "Unavailable"}
      </td>
      <td className="p-2 md:p-3 font-gsans-light font-medium">
        {locations?.filter((i) => i?.id == data?.pickUpLocation)[0]?.name || ""}
      </td>
      <td className="p-2 md:p-3 font-gsans-medium font-medium">
        {data.additionalPhone}
      </td>
      <td className="p-2 md:p-3 font-gsans-light font-medium">
        {data.pickUpDate.substring(0, 10)}
      </td>
      <td
        className={`p-2 md:p-3 ${
          statusColor[data.status.trim()]
        } font-gsans-light font-medium`}
      >
        {data.status}
      </td>
    </tr>
  );
};

export default List;

import { WasteComponent } from "../../components/wasteComponent";
import Layout from "../../common/layout";
import { useState, useContext, useEffect } from "react";
import { Context, UserInfo, getUser } from "../../context/UserDetails";
import { useModal } from "../../context/modal";
import { getAllItems } from "../../api/handlers";
import { useNavigate, useParams } from "react-router";

const Items = (): JSX.Element => {
  const navigate = useNavigate();
  const contextValue = useContext(Context);
  const { user, items, setItems } = contextValue;
  const { setModalToggle, initialState, modalToggle } = useModal();
  const [loggedIn, setLoggedIn] = useState<Boolean>(
    getUser().loggedIn || false
  );
  useEffect(() => {
    async function getAcceptableItems() {
      try {
        const allItems = await getAllItems();
        setItems(allItems.data);
      } catch (error) {}
    }
    getAcceptableItems();
  }, [modalToggle]);

  useEffect(() => {
    if (!loggedIn) {
      navigate("/login");
    }
  });
  return (
    loggedIn && (
      <Layout
        showSearch={true}
        type="Item"
        text="Acceptable Items's"
        user={user as UserInfo}
      >
        <WasteComponent
          Data={items}
          _style="grid -z-10 lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-5  "
        />
      </Layout>
    )
  );
};

export default Items;

import { modalProps } from "../types";
import Layout from "../modals";
import { useState, useContext, useEffect } from "react";
import { useModal } from "../context/modal";
import { useNavigate } from "react-router";
import Chart from "react-apexcharts";
import { Context } from "../context/UserDetails";
import { ResponseBody, getUserBookingOverview } from "../api/handlers";
type valueProps = {
  [key: string]: string;
};

const UserDetails: React.FC<modalProps> = ({
  open,
  name,
  text,
}): JSX.Element => {
  const { initialState, setModalToggle } = useModal();
  const navigate = useNavigate();
  const contextValue = useContext(Context);
  const { user, selectedUser } = contextValue;
  const [chartData, setChartData] = useState<Array<number>>([]);
  const [chartOptions, setChartOptions] = useState<Array<string>>([]);
  const bookingValues = {
    firstName: "Ajani Ben Dara",
    email: "gjc@gmail.com",
    phone: "08100000001",
    phone2: "08100000001",
    address: "Ikeja",
  };
  useEffect(() => {
    async function getOverview() {
      if (!selectedUser) return false;
      try {
        const response: ResponseBody = await getUserBookingOverview(
          user?.accessToken as string,
          selectedUser?.id as string
        );
        if (response.status == 201) {
          const values: Array<number> = Object.values(response?.data ?? []);
          const keys: Array<string> = Object.keys(response?.data ?? []);
          setChartData(values);
          setChartOptions(keys);
        }
      } catch (error) {
        console.log(error);
      }
    }
    getOverview();
  }, [selectedUser]);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setModalToggle({
      ...initialState,
      doneOpen: true,
      detailsModal: true,
      successText: "you changes as been successfully made",
    });
  };

  const donutOptions = {
    chart: {
      width: 100,
    },
    colors: ["#FCB495", "#000000", "#BAEDBD"],
    plotOptions: {
      formatter: function (val: number) {
        return `  `;
      },
      pie: {
        startAngle: -90,
        endAngle: 270,

        donut: {
          size: "68%",
        },
      },
    },
    labels: chartOptions,
    fill: {
      type: "gradient",
    },
    dataLabels: {
      enabled: true,
    },
  };

  const [values, setValues] = useState<valueProps>(bookingValues);

  const series = chartData;
  return (
    <>
      <Layout width="min-w-[60vw]" name={name} open={open} text={text}>
        <section className="grid grid-cols-2">
          <div className="w-32 h-32 rounded-full overflow-hidden">
            <img
              className="object-cover"
              src={selectedUser?.photo}
              alt="profile image"
            />
          </div>
          <div className="bg-[#F7F9FB] p-3 rounded-md">
            <Chart type="donut" options={donutOptions} series={series} />
          </div>
        </section>

        <form>
          <section>
            <h2 className="text-xl capitalize text-primary font-gsans-light">
              Basic Information
            </h2>
            <div className="grid md:grid-cols-2 md:gap-10 gap-1">
              <div className="flex my-3 flex-col">
                <label className="pb-3 text-sm" htmlFor={name}>
                  Full Name
                </label>
                <input
                  disabled
                  className={`border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2] text-sm outline-none `}
                  type="text"
                  value={selectedUser?.fullName}
                />
              </div>
              <div className="flex my-3 flex-col">
                <label className="pb-3 text-sm" htmlFor={name}>
                  Email
                </label>
                <input
                  disabled
                  className={`border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2] text-sm outline-none `}
                  type="text"
                  value={selectedUser?.email}
                />
              </div>
            </div>
            <div className="grid md:grid-cols-2 md:gap-10 gap-1">
              <div className="flex my-3 flex-col">
                <label className="pb-3 text-sm" htmlFor={name}>
                  Phone Number
                </label>
                <input
                  disabled
                  className={`border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2] text-sm outline-none `}
                  type="text"
                  value={selectedUser?.phone}
                />
              </div>
            </div>
          </section>
        </form>
      </Layout>
    </>
  );
};

export default UserDetails;

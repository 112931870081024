import Layout from "../common/layout";
import { ButtonComponent } from "../components/Tags/button";
import { UsersData, userHeader, UserButtonState } from "../data";
import { useEffect, useState, useContext } from "react";
import { Booking, Context, UserInfo, getUser } from "../context/UserDetails";
import UsersList from "./UsersList";
import { getAllBookings, getTopUsers } from "../api/handlers";
import TopUsersList from "./TopUsersList";
import { useNavigate, useParams } from "react-router";

export interface TopUsers {
  user: string;
  totalBookings: number;
}

const Users = (): JSX.Element => {
  const [status, setStatus] = useState("all");
  const [loggedIn, setLoggedIn] = useState<Boolean>(getUser().loggedIn || false);
  const contextValue = useContext(Context);
  const { user, setUser, items, allUsers } = contextValue;
  const [allBookings, setAllBookings] = useState<Array<Booking>>([]);
  const [topUsers, setTopUsers] = useState<Array<TopUsers>>([]);
  const navigate = useNavigate();
  const statusColor: {
    [key: string]: string;
  } = {
    pending: "text-blue-600",
    successful: "text-primary",
    cancelled: "text-red-600",
  };

  useEffect(() => {
    async function getAllPickUps() {
      if (!user?.accessToken) return false;
      try {
        const response = await getAllBookings(user?.accessToken as string);

        if (response.status == 201) {
          setAllBookings(response.data?.reverse());
        }
      } catch (error) {
        console.log(error);
      }
    }
    getAllPickUps();
  }, []);

  useEffect(() => {
    async function getAllTopUser() {
      if (!user?.accessToken) return false;
      try {
        const response = await getTopUsers(user?.accessToken as string);
        if (response.status == 201) {
          setTopUsers(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    getAllTopUser();
  }, []);

  useEffect(()=>{
    if (!loggedIn) {
      navigate('/login');
    }
  })
  return (
    loggedIn && <Layout text={`Bookings ${allBookings.length}`} user={user as UserInfo}>
      <section>
        {/* <OverviewComponent /> */}
        <nav className="grid md:grid-cols-5 grid-cols-2 md:gap-5 gap-3 my-10">
          {UserButtonState.map((data, index) => {
            return (
              <ButtonComponent
                key={index}
                type={data.status.trim() == status ? "dark" : "light"}
                clickHandler={() => setStatus(data.status)}
                _style="md:px-2 px-1 border-[1px] border-primary"
                title={data.name}
              />
            );
          })}
        </nav>
        <div className="overflow-x-scroll ">
          <table className="  my-2  table-auto w-full ">
            <thead>
              <tr style={{ whiteSpace: "nowrap" }} className="p-3 bg-[#F8FDF9]">
                {userHeader.map((data, index) => {
                  return (
                    <th key={index} className=" p-3 border-r-2">
                      {data}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="my-3">
              {status == "all" &&
                allUsers
                  ?.reverse()
                  ?.map((data, index) => (
                    <UsersList
                      data={data}
                      key={index}
                      token={user?.accessToken}
                    />
                  ))}
              {status != "all" &&
                topUsers
                  ?.reverse()
                  ?.map((data, index) => (
                    <TopUsersList
                      data={data}
                      key={index}
                      token={user?.accessToken}
                    />
                  ))}
            </tbody>
          </table>
        </div>
      </section>
    </Layout>
  );
};

export default Users;

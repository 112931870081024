import Layout from "../../common/layout";
import { ButtonType, adminData } from "../../data";
import { ButtonComponent } from "../../components/Tags/button";
import { useEffect, useContext } from "react";
import EditAdmin from "./edit";
import { useModal } from "../../context/modal";
import { useState } from "react";
import { values } from "../../types";
import { Context, UserInfo, getUser } from "../../context/UserDetails";
import { getEveryUser } from "../../api/handlers";
import { useNavigate } from "react-router";
const Admin = (): JSX.Element => {
  const { initialState, modalToggle, setModalToggle } = useModal();
  const contextValue = useContext(Context);
  const { user } = contextValue;

  const navigate = useNavigate();
  const [users, setUsers] = useState<Array<UserInfo>>([]);
  const [selectedUser, setSelectedUser] = useState<UserInfo | null>(null);
  const [loggedIn, setLoggedIn] = useState<Boolean>(
    getUser().loggedIn || false
  );

  const openEdit = (data: any) => {
    setModalToggle({ ...initialState, editUser: true });
  };
  useEffect(() => {
    async function getUsers() {
      try {
        const allUsers = await getEveryUser(user?.accessToken as string);
        const users: Array<any> = allUsers?.data?.filter(
          (i: any) => i?.roles?.toLowerCase() != "user"
        );
        setUsers(users);
      } catch (error) {}
    }
    getUsers();
  }, [user, modalToggle]);
  // const [data, setData] = useState()

  const statusStyle: {
    [key: string]: string;
  } = {
    pending: "bg-[#072A6C]",
    active: "bg-primary",
    suspended: "bg-[#C93000]",
  };

  useEffect(() => {
    if (!loggedIn) {
      navigate("/login");
    }
  });
  return (
    <>
      {loggedIn && (
        <>
          <EditAdmin
            open={modalToggle.editUser}
            setOpen={() => setModalToggle({ ...initialState })}
            selectedUser={selectedUser as UserInfo}
            setSelectedUser={
              setSelectedUser as React.Dispatch<React.SetStateAction<UserInfo>>
            }
          />
          <Layout
            showSearch={true}
            type="user"
            text="Admin"
            user={user as UserInfo}
          >
            <div className="overflow-x-scroll ">
              <table className="table-auto  p-4 w-full">
                <thead>
                  <tr className="text-xl  text-start font-bold font-gsans0-medium">
                    <th className="text-start">Email</th>
                    <th className="text-start">Role</th>
                    <th className="text-start">Status</th>
                  </tr>
                </thead>

                <tbody>
                  {users?.map((data, index) => {
                    return (
                      <tr
                        onClick={() => {
                          setSelectedUser(data);
                          openEdit(data);
                        }}
                        className="cursor-pointer"
                        key={index}
                      >
                        <td className=" px-2  py-4">{data?.email}</td>
                        <td>{data?.roles}</td>
                        <td>
                          <button
                            type="button"
                            className={`font-light text-base text-light font-gsans-light px-5 py-2 rounded-md ${data?.status}-status`}
                          >
                            Manage
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Layout>
        </>
      )}
    </>
  );
};

export default Admin;

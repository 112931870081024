import { modalProps } from "../types";
import Layout from "../modals";
import { InputComponent, SelectComponent } from "../components/Tags/input";
import { ButtonComponent } from "../components/Tags/button";
import { useState, useEffect, useContext } from "react";
import { useModal } from "../context/modal";
import { WasteComponent } from "../components/wasteComponent";
import { DoneComponent } from "../modals/action";
import { ButtonType, WastesData } from "../data";
import { useNavigate } from "react-router";
import { Context, Items } from "../context/UserDetails";
import { updateBookingStatus } from "../api/handlers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
type valueProps = {
  [key: string]: string;
};

const BookingDetails: React.FC<modalProps> = ({
  open,
  name,
  text,
}): JSX.Element => {
  const { initialState, setModalToggle } = useModal();
  const navigate = useNavigate();
  const contextValue = useContext(Context);
  const {
    locations,
    allUsers,
    setSelectedBooking,
    selectedBooking,
    items,
    user,
  } = contextValue;

  const bookingValues = {
    firstName: "Ajani Ben Dara",
    email: "gjc@gmail.com",
    phone: "08100000001",
    phone2: "08100000001",
    address: "Ikeja",
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setModalToggle({
      ...initialState,
      doneOpen: true,
      detailsModal: true,
      successText: "you changes as been successfully made",
    });
  };
  const [values, setValues] = useState<valueProps>(bookingValues);
  const [wastes, setWastes] = useState<Array<Items>>([]);
  const [status, setStatus] = useState<string>("successful");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const allBookingItems: Array<string> =
      selectedBooking?.items || ([] as Array<string>);
    const wastesData = allBookingItems?.map((i) => {
      return {
        id: i,
        name: items?.filter((x) => x?.id == i)?.[0]?.name,
        photo: items?.filter((x) => x?.id == i)?.[0]?.photo,
      };
    });
    setWastes(wastesData);
  }, [selectedBooking]);

  const handleClick = async () => {
    setLoading(true);
    try {
      const response = await updateBookingStatus(user?.accessToken as string, {
        bookingId: selectedBooking?.id,
        status,
      });
      setLoading(false);
      if (response.status == 201) {
        setModalToggle({
          ...initialState,
          doneOpen: true,
          detailsModal: true,
          successText: "your changes has been successfully made",
        });
      } else {
        console.log(response);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <>
      <Layout width="min-w-[60vw]" name={name} open={open} text={text}>
        <h2 className="text-primary tracking-wider text-base">
          Booking Details
        </h2>
        <div className="grid md:grid-cols-2 md:gap-10 gap-1">
          <div className="flex my-3 flex-col">
            <label className="pb-3 text-sm" htmlFor={name}>
              Booking ID
            </label>
            <input
              disabled
              className={`border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2] text-sm outline-none `}
              type="text"
              value={selectedBooking?.bookingId}
            />
          </div>
          <div className="flex my-3 flex-col">
            <label className="pb-3 text-sm" htmlFor={name}>
              Booking Status
            </label>
            <select
              className={`border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2] text-sm outline-none`}
              onChange={(e) => setStatus(e.currentTarget.value)}
            >
              <option value="successful">Successful</option>
              <option value="pending">Pending</option>
              <option value="cancelled">Cancelled</option>
            </select>
          </div>
          <SelectComponent
            options={[
              { name: "Successful", value: "successful" },
              { name: "Cancel", value: "cancel" },
              { name: "Pending", value: "pending" },
            ]}
            name="status"
            values={values}
            setValues={setValues}
            label="Booking Status"
          />
        </div>
        <h2 className="text-primary tracking-wider my-4 text-xs font-medium   ">
          Selected pick Up waste Item’s
        </h2>
        <WasteComponent
          Data={wastes}
          _style="grid lg:grid-cols-4 my-2 gap-3 md:grid-cols-3 grid-cols-2"
        />
        <form>
          <section>
            <h2 className="text-xl capitalize text-primary font-gsans-light">
              Basic Information
            </h2>
            <div className="grid md:grid-cols-2 md:gap-10 gap-1">
              <div className="flex my-3 flex-col">
                <label className="pb-3 text-sm" htmlFor={name}>
                  Full Name
                </label>
                <input
                  disabled
                  className={`border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2] text-sm outline-none `}
                  type="text"
                  value={
                    allUsers?.filter((i) => i?.id == selectedBooking?.user)?.[0]
                      ?.fullName || "Unavailable"
                  }
                />
              </div>
              <div className="flex my-3 flex-col">
                <label className="pb-3 text-sm" htmlFor={name}>
                  Email
                </label>
                <input
                  disabled
                  className={`border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2] text-sm outline-none `}
                  type="text"
                  value={
                    allUsers?.filter((i) => i?.id == selectedBooking?.user)?.[0]
                      ?.email || "Unavailable"
                  }
                />
              </div>
            </div>
            <div className="grid md:grid-cols-2 md:gap-10 gap-1">
              <div className="flex my-3 flex-col">
                <label className="pb-3 text-sm" htmlFor={name}>
                  Phone Number
                </label>
                <input
                  disabled
                  className={`border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2] text-sm outline-none `}
                  type="text"
                  value={
                    allUsers?.filter((i) => i?.id == selectedBooking?.user)?.[0]
                      ?.phone || "Unavailable"
                  }
                />
              </div>
              <div className="flex my-3 flex-col">
                <label className="pb-3 text-sm" htmlFor={name}>
                  Additional Phone Number
                </label>
                <input
                  disabled
                  className={`border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2] text-sm outline-none `}
                  type="text"
                  value={selectedBooking?.additionalPhone}
                />
              </div>
            </div>
            <h3 className="text-primary my-3 font-semibold text-sm">
              This number will be used for verification
            </h3>
          </section>
          <section>
            <h2 className="text-xl capitalize text-primary font-gsans-light">
              Pick Up details
            </h2>
            <div className="grid md:grid-cols-2 md:gap-10 gap-1">
              <div className="flex my-3 flex-col">
                <label className="pb-3 text-sm" htmlFor={name}>
                  Pick Up Address
                </label>
                <input
                  disabled
                  className={`border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2] text-sm outline-none `}
                  type="text"
                  value={selectedBooking?.additionalPhone}
                />
              </div>
              <div className="flex my-3 flex-col">
                <label className="pb-3 text-sm" htmlFor={name}>
                  Area
                </label>
                <input
                  disabled
                  className={`border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2] text-sm outline-none `}
                  type="text"
                  value={
                    locations?.filter(
                      (i) => i?.id == selectedBooking?.pickUpLocation
                    )?.[0]?.name || "Unavailable"
                  }
                />
              </div>
            </div>
            <div className="grid md:grid-cols-2 md:gap-10 gap-1">
              <div className="flex my-3 flex-col">
                <label className="pb-3 text-sm" htmlFor={name}>
                  Pick Up Date
                </label>
                <input
                  disabled
                  className={`border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2] text-sm outline-none `}
                  type="text"
                  value={selectedBooking?.pickUpDate?.substring(0, 10)}
                />
              </div>
              <div className="flex my-3 flex-col">
                <label className="pb-3 text-sm" htmlFor={name}>
                  Pick Up Time Range
                </label>
                <input
                  disabled
                  className={`border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2] text-sm outline-none `}
                  type="text"
                  value={selectedBooking?.pickUpTime}
                />
              </div>
            </div>
          </section>
          <div className="flex my-3 items-center justify-center">
            <button
              type="button"
              className={`${
                ButtonType[loading ? "light" : "dark"]
              } font-light text-base text-light font-gsans-light px-5 py-2 rounded-md w-48 font-gsans-light ${
                loading && "not-allowed"
              }`}
              onClick={(e) => {
                e.preventDefault();
                !loading && handleClick();
              }}
            >
              {loading ? "Loading..." : "Update"}
            </button>
          </div>
        </form>
      </Layout>
      <ToastContainer />
    </>
  );
};

export default BookingDetails;

import React, {useContext, useState, useEffect} from 'react';
import { Booking, Context, UserInfo } from '../context/UserDetails';
import { getUserBookingStat } from '../api/handlers';
import { TopUsers } from './users';

import { useModal } from '../context/modal';

interface MyComponentProps {
    data: TopUsers;
    token? : string
  }
  const statusColor: {
    [key: string]: string;
  } = {
    pending: "text-blue-600",
    successful: "text-primary",
    cancelled: "text-red-600",
  };

  interface BookingStats {
    totalBookings?: number | null,
    pendingBookings? : number | null,
    cancelledBookings? : number| null,
    successfulBookings? : number| null,
  }



const TopUsersList: React.FC<MyComponentProps> = ({data, token }) => {
  const contextValue = useContext(Context);
  const { locations, allUsers } = contextValue;
  const [userDetails, setUserDetails] = useState<UserInfo>(allUsers.filter(i => i?.id == data?.user)[0] || {});
  const [bookingStats, setBookingStats] = useState<BookingStats>({
    "totalBookings": null,
    "pendingBookings": null,
    "cancelledBookings": null,
    "successfulBookings": null
});


const {setModalToggle,initialState,modalToggle} = useModal()
  
  useEffect(()=>{
    async function getStats() {
        try {
            const response = await getUserBookingStat(token as string, data?.user as string);
            if(response.status == 201){
                setBookingStats(response.data);
            }
        } catch (error) {
            
        }
    }
    getStats();
  }, [])
    return (
    
        <tr onClick={()=> setModalToggle({...initialState, userModal :true})} className="text-center cursor-pointer border-b p-2 ">
        <td className="p-2 md:p-3 font-gsans-light font-medium">{userDetails?.userId}</td>
        <td className="p-2 md:p-3 font-gsans-light font-medium">{userDetails?.fullName}</td>
        <td className="p-2 md:p-3 font-gsans-light font-medium">
          {userDetails?.email}
        </td>
        <td
          style={{ whiteSpace: "nowrap" }}
          className="p-2 md:p-3 font-gsans-light font-medium"
        >
          {userDetails?.phone}
        </td>
        <td
          style={{ whiteSpace: "nowrap" }}
          className="p-2 md:p-3 font-gsans-medium font-medium"
        >
          {bookingStats?.totalBookings}
        </td>
        <td className="p-2 md:p-3 font-gsans-light font-medium">
        {bookingStats?.pendingBookings}
        </td>
      </tr>
      )
};

export default TopUsersList;

import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import {useState, useEffect, useContext} from 'react'
import { useNavigate } from "react-router";
import { Context } from "../context/UserDetails";
import { ResponseBody, getBookingOverview, getWeeklyBooks } from "../api/handlers";
const Charts = (): JSX.Element => {
  const navigate = useNavigate();
  const contextValue = useContext(Context);
  const { user, setUser, items } = contextValue;
  const [pieData, setPieData] = useState<Array<number>>([]);
  const [pieCategories, setPieCategories] = useState<Array<string>>([]);
  const [barData, setBarData] = useState<Array<any>>([]);
  const [barCategories, setBarCategories] = useState<Array<string>>([]);

  useEffect(()=>{
    async function getOverview() {
      try {
        const response : ResponseBody | undefined = await getBookingOverview(user?.accessToken as string);
        if(response.status == 201){
          let values = Object.values(response.data as number);
          let keys = Object.keys(response.data);
          setPieData(values);
          setPieCategories(keys);
        }
      } catch (error) {
        
      }
    }
    getOverview();
  },[]);

  useEffect(()=>{
    async function getWeekBooks() {
      try {
        const response : ResponseBody | undefined = await getWeeklyBooks(user?.accessToken as string);
        if(response.status == 201){
          setBarCategories(response?.data?.map((i : any) => i.day));
          setBarData(response?.data?.map((i : any) => {
            return {
              "successfulBookings" : i.successfulBookings,
              "pendingBookings" : i.pendingBookings,
              "cancelledBookings" : i.cancelledBookings,
            }
          }));
        }
      } catch (error) {
        
      }
    }
    getWeekBooks();
  },[]);
  const barChartOptions: ApexOptions = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
    },
    plotOptions: {
      bar: {
        barHeight: "100%",
      },
    },
    dataLabels: {
      enabled: false,
      // enabledOnSeries: [1]
    },

    title: {
      text: "Daily Books",
    },
    xaxis: {
      categories: barCategories,
    },
    yaxis: {
      title: {
        text: undefined,
      },
    },
    fill: {
      colors: ["#126343", "#072A6C", "#C93000"],
      opacity: 1,
    },
  };

  // const barChartOptions: ApexOptions = {
  //   xaxis: {
  //     categories: ["New bookings ", "Pending ", "Completed ", "Cancelled "],
  //   },
  //   colors: ["#BAEDBD", "#C6C7F8", "#000000", "#B1E3FF"],

  //   dataLabels: {
  //     enabled: false,
  //   },
  //   legend: {
  //     show: false,
  //   },

  //   plotOptions: {
  //     bar: {
  //       columnWidth: "45%",
  //       distributed: true,
  //       borderRadiusApplication: "end",
  //       borderRadius: 8,

  //       horizontal: false,
  //     },
  //   },

  //   chart: {
  //     stacked: true,
  //     stackType: "100%",
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  // };

  const barStyle = {
    borderRadius: "4px",
    borderBottomLeftRadius: "0",
    borderBottomRightRadius: "0",
  };
  const series1 = [
    {
      name: "Successful Pickup",
      data: barData?.map((i : any) => i?.successfulBookings),
    },
    {
      name: "Pending  Pickup",
      data: barData?.map((i : any) => i?.pendingBookings),
    },
    {
      name: "Cancelled Pickup",
      data: barData?.map((i : any) => i?.cancelledBookings),
    },
  ];
  const series2 = pieData;

  const donutOptions = {
    chart: {
      width: 100,
    },
    title: {
      text: "System Overview",
    },
    colors: ["#126343", "#C93000", "#072A6C"],
    plotOptions: {
      formatter: function (val: number) {
        return `  `;
      },
      pie: {
        startAngle: -90,
        endAngle: 270,

        donut: {
          size: "68%",
        },
      },
    },
    labels: pieCategories,
    fill: {
      type: "gradient",
    },
    dataLabels: {
      enabled: true,
    },
  };

  return (
    <main className="my-5">
      <h1 className="text-base font-medium my-10 font-gsans-medium">
        Bookings
      </h1>
      <section className="grid grid-cols-1 md:grid-cols-2 gap-5    ">
        <div className="bg-[#F8FDF9] p-3 rounded-2xl">
          {/* <h1 className="text-base font-medium font-gsans-medium">
            Daily Book's
          </h1> */}
          <Chart
            type="bar"
            style={barStyle}
            options={barChartOptions}
            series={series1}
          />
        </div>
        <div className="bg-[#F8FDF9] p-3 rounded-2xl">
          {/* <h1 className="text-base font-medium font-gsans-medium">
            System Overview
          </h1> */}
          <Chart type="donut" options={donutOptions} series={series2} />
        </div>
      </section>
    </main>
  );
};

export default Charts;

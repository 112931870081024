import React, { useState, useContext } from "react";
import SubLayout from "../../common/subLayout";
import { InputComponent, SelectComponent } from "../../components/Tags/input";
import { ButtonComponent } from "../../components/Tags/button";
import { useModal } from "../../context/modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { values } from "../../types";
import { Context } from "../../context/UserDetails";
import { ButtonType } from "../../data";
import { createNewUser } from "../../api/handlers";

import { BiHide, BiShow } from "react-icons/bi";
const Admin: React.FC<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ open, setOpen }): JSX.Element => {
  const [values, setValues] = useState<values>({});
  const contextValue = useContext(Context);
  const { user } = contextValue;
  const { setModalToggle, initialState } = useModal();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState("admin");

  const [show, setShow] = useState("password");

  const handleShowPassword = () => {
    show === "password" ? setShow("text") : setShow("password");
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setModalToggle({
      ...initialState,
      doneOpen: true,
      successText:
        " New user  as been created successfully the user password as been sent to is mail",
    });
    console.log(values);
  };

  const handleClick = async () => {
    setLoading(true);
    try {
      const response = await createNewUser(user?.accessToken as string, {
        email,
        password,
        roles,
      });
      setLoading(false);
      if (response.status == 201) {
        setModalToggle({
          ...initialState,
          doneOpen: true,
          successText:
            " New user  as been created successfully the user password as been sent to is mail",
        });
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <>
      <SubLayout title="Create New User" open={open} setOpen={setOpen}>
        <form className="my-5 ">
          <div className="flex my-3 flex-col">
            <label className="pb-3 text-sm" htmlFor="email">
              User email
            </label>
            <input
              className={`w-full border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2] text-sm outline-none `}
              onChange={(e) => {
                setEmail(e.currentTarget.value);
              }}
              type={"text"}
              value={email}
            />
          </div>

          <div className="flex my-3 flex-col">
            <label className="pb-3 text-sm" htmlFor="email">
              Password
            </label>
            <div className="relative">
              <input
                className={`w-full border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2] text-sm outline-none `}
                onChange={(e) => {
                  setPassword(e.currentTarget.value);
                }}
                type={show}
                value={password}
              />
              <span
                className="absolute cursor-pointer right-3 top-1/2 bottom-0 -translate-y-1/2 "
                onClick={handleShowPassword}
              >
                {show === "password" ? <BiHide /> : <BiShow />}
              </span>
            </div>
          </div>
          <div className="flex my-3 flex-col">
            <label className="pb-3 text-sm" htmlFor="role">
              Role
            </label>
            <select
              className={`border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2] text-sm outline-none`}
              value={roles}
              onChange={(e) => setRoles(e.currentTarget.value)}
            >
              <option value="admin">Admin</option>
              <option value="user">User</option>
            </select>
          </div>
          <div className="my-20 flex justify-center">
            <button
              type="button"
              className={`${
                ButtonType[loading ? "light" : "dark"]
              } font-light text-base text-light font-gsans-light px-5 py-2 rounded-md px-5`}
              onClick={(e) => {
                e.preventDefault();
                if (!email || !password) {
                  toast.error("Enter required fields!");
                  return false;
                }
                handleClick();
              }}
            >
              {loading ? "Loading..." : "Create User"}
            </button>
          </div>
        </form>
      </SubLayout>
      <ToastContainer />
    </>
  );
};

export default Admin;

import React, { useState, useContext } from "react";
import SubLayout from "../../common/subLayout";
import { InputComponent } from "../../components/Tags/input";
import { ButtonComponent } from "../../components/Tags/button";

import { useModal } from "../../context/modal";

import thumbnail from "../../assets/thumbnail.png";
import uploadBtn from "../../assets/uploadbtn.png";

import { values } from "../../types";
import { ButtonType } from "../../data";
import { ResponseBody, createAcceptableItem } from "../../api/handlers";
import { Context } from "../../context/UserDetails";
const Item: React.FC<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ open, setOpen }): JSX.Element => {
  const contextValue = useContext(Context);
  const { user, items, setItems } = contextValue;
  const [values, setValues] = useState<values>({});
  const { setModalToggle, initialState } = useModal();
  const [name, setName] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [itemFile, setItemFile] = useState<File | null>();
  const [base64String, setBase64String] = useState<string>("");

  const handleImageChange = (itemFile: any) => {
    if (itemFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // When the file reader is done reading, set the base64 string in the state
        if (typeof reader.result === "string") {
          setBase64String(reader.result);
        }
      };
      reader.readAsDataURL(itemFile);
    }
  };
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setModalToggle({
      ...initialState,
      doneOpen: true,
      successText:
        "You have successfully added a new item that you will be accepting, and it will be reflected in the system immediately",
    });
    console.log(values);
  };
  const handleClick = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("photo", itemFile as Blob);
      const response: ResponseBody = await createAcceptableItem(
        user?.accessToken as string,
        formData
      );
      setLoading(false);
      if (response.status == 201) {
        setItems([...items, response.data]);
        setModalToggle({
          ...initialState,
          doneOpen: true,
          successText:
            "You have successfully added a new item that you will be accepting, and it will be reflected in the system immediately",
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <SubLayout title="Add New Acceptable Item" open={open} setOpen={setOpen}>
      <form className="my-5 ">
        <div className="mx-auto w-56 relative">
          <img
            className="w-32 mx-auto"
            src={base64String || thumbnail}
            alt="thumbnail"
          />
          <label
            className="absolute cursor-pointer bottom-0 right-10"
            htmlFor="item"
          >
            <img src={uploadBtn} alt="upload btn" />
          </label>
          <input
            className="hidden"
            type="file"
            id="item"
            onChange={(e) => {
              handleImageChange(e?.currentTarget?.files?.[0]);
              setItemFile(e?.currentTarget?.files?.[0]);
            }}
          />
        </div>
        <div className="flex my-3 flex-col">
          <label className="pb-3 text-sm" htmlFor="name">
            Item Name
          </label>
          <input
            className={`w-full border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2] text-sm outline-none `}
            type="text"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
        </div>
        <div className="my-5 flex justify-center">
          <button
            type="button"
            className={`${
              loading ? ButtonType["light"] : ButtonType["dark"]
            } font-light text-base text-light font-gsans-light px-5 py-2 rounded-md px-5`}
            onClick={(e) => {
              e.preventDefault();
              if (!loading && name && itemFile) handleClick();
            }}
          >
            {loading ? "Loading" : "Upload"}
          </button>
        </div>
      </form>
    </SubLayout>
  );
};

export default Item;

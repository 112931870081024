import { Context, Items } from "../context/UserDetails";
import { useModal } from "../context/modal";
import { WastesData } from "../data";
import { values } from "../types";
import { ImageComponent } from "./Tags/image";
import { useContext, useEffect, useState } from "react";
export const WasteComponent = ({
  // _style = "grid lg:grid-cols-4 bg-red-500 place-items-center  items-center justify-center mx-auto md:grid-cols-3 grid-cols-2",
  _style = "md:flex grid grid-cols-2 flex-wrap items-center gap-10 md:justify-center",
  Data,
  Click = true,
}: {
  _style?: string;
  Data?: Array<Items>;
  Click?: Boolean;
}): JSX.Element => {
  const { setModalToggle, initialState, modalToggle } = useModal();
  const contextValue = useContext(Context);
  const { items, setSelectedItem } = contextValue;
  const handleSubmit = (data: values) => {
    setModalToggle({ ...initialState, editItem: true });
  };
  const [contents, setContents] = useState<Array<Items>>([]);
  useEffect(() => {
    setContents(Data ? Data : items);
  }, [modalToggle, items]);
  return (
    <section className={`${_style}  -z-50 gap-6  `}>
      {contents?.map((data, index) => {
        return (
          <div
            onClick={() => {
              if (!Click) return false;
              setSelectedItem(data);
              handleSubmit(data);
            }}
            className="bg-secondary  cursor-pointer  flex flex-col justify-between   "
            key={index}
          >
            <div className="  flex h-full p-3  ">
              <ImageComponent
                _style=" w-full h-full "
                src={data?.photo as string}
              />
            </div>
            <p className="bg-primarySm capitalize text-center text-light p-3">
              {data?.name}
            </p>
          </div>
        );
      })}
    </section>
  );
};

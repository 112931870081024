import Layout from "../common/layout";
import { OverviewComponent } from "../components/overview";
import { ButtonComponent } from "../components/Tags/button";

import { AllPickups, headerData, buttonStates } from "../data";

import { useEffect, useState, useContext } from "react";
import { Booking, Context, UserInfo, getUser } from "../context/UserDetails";
import { useNavigate } from "react-router";

import { useModal } from "../context/modal";

import { values } from "../types";
import { getAllBookings, getNewBookings, getUserInfo } from "../api/handlers";
import BookingDetails from "./bookingDetails";
import List from "./bookingsList";

const Bookings = (): JSX.Element => {
  const [status, setStatus] = useState("all");
  const [contents, setContents] = useState<Array<Booking>>([]);
  const [loggedIn, setLoggedIn] = useState<Boolean>(
    getUser().loggedIn || false
  );
  const [allBookings, setAllBookings] = useState<Array<Booking>>([]);
  const [allNewBookings, setAllNewBookings] = useState<Array<Booking>>([]);
  const [pendingBookings, setPendingBookings] = useState<Array<Booking>>([]);
  const [cancelledBookings, setCancelledBookings] = useState<Array<Booking>>(
    []
  );
  const [successfulBookings, setSuccessfulBookings] = useState<Array<Booking>>(
    []
  );
  const navigate = useNavigate();
  const contextValue = useContext(Context);

  const { user, setUser, items, locations } = contextValue;
  const { modalToggle } = useModal();

  useEffect(() => {
    if (!loggedIn) {
      navigate("/login");
    }
  });

  const statusColor: {
    [key: string]: string;
  } = {
    pending: "text-blue-600",
    successful: "text-primary",
    cancelled: "text-red-600",
  };

  const FilterData = (): any[] => {
    let data;
    if (status == "all") data = AllPickups;
    else {
      data = AllPickups.filter((data, index) => {
        return data.status.trim() == status;
      });
    }

    return data;
  };

  useEffect(() => {
    setContents(
      status == "all"
        ? allBookings
        : status == "new"
        ? allNewBookings
        : status == "pending"
        ? pendingBookings
        : status == "cancelled"
        ? cancelledBookings
        : status == "successful"
        ? successfulBookings
        : allBookings
    );
  }, [
    allBookings,
    pendingBookings,
    successfulBookings,
    cancelledBookings,
    status,
    allNewBookings,
  ]);

  useEffect(() => {
    async function getAllPickUps() {
      if (!user?.accessToken) return false;
      try {
        const response = await getAllBookings(user?.accessToken as string);

        if (response.status == 201) {
          setAllBookings(response.data?.reverse());
        }
      } catch (error) {
        console.log(error);
      }
    }
    getAllPickUps();
  }, [modalToggle]);
  useEffect(() => {
    async function getAllPickUps() {
      if (!user?.accessToken) return false;
      try {
        const response = await getNewBookings(user?.accessToken as string);

        if (response.status == 201) {
          setAllNewBookings(response.data?.reverse());
        }
      } catch (error) {
        console.log(error);
      }
    }
    getAllPickUps();
  }, [modalToggle]);

  useEffect(() => {
    setCancelledBookings(allBookings?.filter((i) => i?.status == "cancelled"));
    setPendingBookings(allBookings?.filter((i) => i?.status == "pending"));
    setSuccessfulBookings(
      allBookings?.filter((i) => i?.status == "successful")
    );
  }, [allBookings]);

  return (
    <>
      {loggedIn && (
        <Layout
          text={`Bookings (${allBookings?.length})`}
          user={user as UserInfo}
        >
          <>
            <section>
              {/* <OverviewComponent /> */}
              <nav className="grid md:grid-cols-5 grid-cols-2 md:gap-5 gap-3 my-10">
                {buttonStates.map((data, index) => {
                  return (
                    <ButtonComponent
                      key={index}
                      type={data.status.trim() == status ? "dark" : "light"}
                      clickHandler={() => setStatus(data.status)}
                      _style="md:px-2 px-1 border-[1px] border-primary"
                      title={data.name}
                    />
                  );
                })}
              </nav>
              <div className="overflow-x-scroll ">
                <table className="  my-2  table-auto w-full ">
                  <thead>
                    <tr
                      style={{ whiteSpace: "nowrap" }}
                      className="p-3 bg-[#F8FDF9]"
                    >
                      {headerData.map((data, index) => {
                        return (
                          <th key={index} className=" p-3 border-r-2">
                            {data}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="my-3">
                    {contents.map((data, index) => (
                      <List data={data} key={index} />
                    ))}
                  </tbody>
                </table>
              </div>
            </section>
          </>
        </Layout>
      )}
    </>
  );
};

export default Bookings;

import { OverviewData } from "../data";
import { useState, useEffect, useContext } from "react";
import overviewBg from "../assets/overviewBg.png";
import credit from "../assets/vector1.png";
import debit from "../assets/vector2.png";
import {
  ResponseBody,
  getAllUserBookingGrowthStats,
  getAllUserBookingStats,
  getAllUserGrowthStats,
  getAllUserStats,
} from "../api/handlers";
import { Context } from "../context/UserDetails";
import { useNavigate } from "react-router";
export const OverviewComponent = (): JSX.Element => {
  const navigate = useNavigate();
  const contextValue = useContext(Context);
  const { user, setUser, items } = contextValue;
  const [data, setData] = useState([
    {
      name: "Total bookings",
      amount: "0",
      percent: "+0%",
      type: "credit",
    },
    {
      name: "Pending Pickup",
      amount: "0",
      percent: "+0%",
      type: "credit",
    },
    {
      name: "New User ",
      amount: "0",
      percent: "+0%",
      type: "credit",
    },
    {
      name: "Active User",
      amount: "0",
      percent: "+0%",
      type: "credit",
    },
  ]);
  useEffect(() => {
    async function getStats() {
      try {
        const bookingStats: ResponseBody | undefined =
          (await getAllUserBookingStats(
            user?.accessToken as string
          )) as ResponseBody;
        const bookingGrowth: ResponseBody | undefined =
          (await getAllUserBookingGrowthStats(
            user?.accessToken as string
          )) as ResponseBody;
        const usersStats: ResponseBody | undefined = (await getAllUserStats(
          user?.accessToken as string
        )) as ResponseBody;
        const usersGrowth: ResponseBody | undefined =
          (await getAllUserGrowthStats(
            user?.accessToken as string
          )) as ResponseBody;
        if (
          bookingStats.status != 201 ||
          bookingGrowth.status != 201 ||
          usersStats.status != 201 ||
          usersGrowth.status != 201
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        }
        setData([
          {
            name: "Total bookings",
            amount: bookingStats.data?.totalBookings ?? 0,
            percent:
              `${bookingGrowth.data?.totalBookingsGrowth >= 0 ? "+" : "-"}${
                bookingGrowth.data?.totalBookingsGrowth
              }%` ?? "0%",
            type: `${
              bookingGrowth.data?.totalBookingsGrowth >= 0 ? "credit" : "debit"
            }`,
          },
          {
            name: "Pending Pickup",
            amount: bookingStats.data?.pendingBookings ?? 0,
            percent:
              `${bookingGrowth.data?.pendingBookingsGrowth >= 0 ? "+" : "-"}${
                bookingGrowth.data?.pendingBookingsGrowth
              }%` ?? "0%",
            type: `${
              bookingGrowth.data?.pendingBookingsGrowth >= 0
                ? "credit"
                : "debit"
            }`,
          },
          {
            name: "New User",
            amount: usersStats.data?.newUsers ?? 0,
            percent:
              `${usersGrowth.data?.newUsersGrowth >= 0 ? "+" : "-"}${
                usersGrowth.data?.newUsersGrowth
              }%` ?? "0%",
            type: `${
              usersGrowth.data?.newUsersGrowth >= 0 ? "credit" : "debit"
            }`,
          },
          {
            name: "Active User",
            amount: usersStats.data?.activeUsers ?? 0,
            percent:
              `${usersGrowth.data?.activeUsersGrowth >= 0 ? "+" : "-"}${
                usersGrowth.data?.activeUsersGrowth
              }%` ?? "0%",
            type: `${
              usersGrowth.data?.activeUsersGrowth >= 0 ? "credit" : "debit"
            }`,
          },
        ]);
      } catch (error) {}
    }
    getStats();
  }, []);

  const renderOverview = data.map((data, index) => {
    return (
      <div
        key={index}
        style={{ backgroundImage: `url(${overviewBg})` }}
        className="bg-primary text-white lg:p-5 md:p-1 p-2 rounded-md"
      >
        <h2 className="text-base tracking-wider ">{data.name}</h2>
        <div className="flex  items-center justify-between">
          <h1 className="lg:text-4xl md:text-xl text-xl font-bold">
            {data.amount}
          </h1>
          <div className="my-4 flex items-center gap-3">
            <p>{data.percent}</p>
            <img src={data.type === "credit" ? credit : debit} />
          </div>
        </div>
      </div>
    );
  });
  return (
    <section className="grid md:grid-cols-4 grid-cols-2 gap-4  md:gap-3 lg:gap-5">
      {renderOverview}
    </section>
  );
};

import logo from "../assets/logo2.jpg";
import logo2 from "../assets/logo3.png";

import { useNavigate } from "react-router-dom";
export const LogoComponent = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <div className="logo">
      <img
        onClick={() => navigate("/")}
        className="md:w-auto cursor-pointer w-28"
        src={logo}
        alt="GJC Logo"
      />
    </div>
  );
};

export const LightLogoComponent = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <div className="logo">
      <img
        className="cursor-pointer w-28"
        onClick={() => navigate("/")}
        src={logo2}
        alt="GJC Logo"
      />
    </div>
  );
};

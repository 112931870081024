import React, { useState, useEffect, useContext } from "react";
import SubLayout from "../../common/subLayout";
import { InputComponent, SelectComponent } from "../../components/Tags/input";
import { ButtonComponent } from "../../components/Tags/button";

import { useModal } from "../../context/modal";

import { values } from "../../types";
import { Context, UserInfo } from "../../context/UserDetails";
import { activateUser, deleteUser, modifyInfo, suspendUser } from "../../api/handlers";
import { ButtonType } from "../../data";

const EditAdmin: React.FC<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedUser: UserInfo;
  setSelectedUser: React.Dispatch<React.SetStateAction<UserInfo>>;
}> = ({ open, setOpen,selectedUser, setSelectedUser }): JSX.Element => {
  const [values, setValues] = useState<values>({});
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState("");
  const [mode, setMode] = useState("modify");
  const { setModalToggle, initialState } = useModal();
  const contextValue = useContext(Context);
  const { user } = contextValue;
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setModalToggle({
      ...initialState,
      doneOpen: true,
      successText: "Your changes has been successfully made",
    });
  };

  const handleClick = () => {
    if(mode == 'activate') handleActivate();
    if(mode == 'suspend') handleSuspend();
    if(mode == 'delete') handleDelete();
    if(mode == 'modify') handleModify();
  };

  const handleSuspend = async () => {
    setLoading(true);
    try {
      const response = await suspendUser(user?.accessToken as string, {userId : selectedUser?.id});
    setLoading(false);
      if(response.status == 201){
        setSelectedUser({});
        setModalToggle({
          ...initialState,
          doneOpen: true,
          successText: "Suspension successful!",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  const handleActivate = async () => {
    setLoading(true);
    try {
      const response = await activateUser(user?.accessToken as string, {userId : selectedUser?.id});
      setLoading(false);
      if(response.status == 201){
        setSelectedUser({});
        setModalToggle({
          ...initialState,
          doneOpen: true,
          successText: "Activation successful!",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await deleteUser(user?.accessToken as string, {userId : selectedUser?.id});
      setLoading(false);
      if(response.status == 201){
        setSelectedUser({});
        setModalToggle({
          ...initialState,
          doneOpen: true,
          successText: "Deletion successful!",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  const handleModify = async () => {
    setLoading(true);
    try {
      const response = await modifyInfo(undefined,user?.accessToken as string, {
        userId : selectedUser?.id,
        email,
        roles
      });
      setLoading(false);
      if(response.status == 201){
        setSelectedUser({});
        setModalToggle({
          ...initialState,
          doneOpen: true,
          successText: "Your changes has been successfully made",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(()=>{
    setEmail(selectedUser?.email as string);
    setRoles(selectedUser?.roles?.toLowerCase() as string);
    setMode('modify');
    console.log(selectedUser);
    
  },[selectedUser])
  return (
    <SubLayout title="Edit User" open={open} setOpen={setOpen}>
      <form className="my-5 ">
          <div className="flex my-3 flex-col">
            <label className="pb-3 text-sm" htmlFor='email'>
              User email
            </label>
            <input
              className={`w-full border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2] text-sm outline-none `}
              onChange={(e) => {
                setEmail(e.currentTarget.value);
              }}
              type={'text'}
              value={email}
            />
        </div>
        <div className="flex my-3 flex-col">
          <label className="pb-3 text-sm" htmlFor='role'>
            Role
          </label>
          <select
            className={`border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2] text-sm outline-none`}
            value={roles}
            onChange={(e)=> setRoles(e.currentTarget.value)}
          >
            <option value="admin">Admin</option>
            <option value="user">User</option>
          </select>
          </div>
        <div className="my-3">
          <label htmlFor="status">Status</label>
          <section className="flex items-center gap-4">
            <div className="flex items-center gap-3">
              <input type="radio" name="status" onClick={()=>{
                setMode('activate');
              }} checked={selectedUser?.status}/>
              <label htmlFor="active">Active</label>
            </div>
            <div className="flex items-center gap-3">
              <input type="radio" name="status"  onClick={()=>{
                setMode('suspend');
              }} checked={!selectedUser?.status}/>
              <label htmlFor="suspend">Suspend</label>
            </div>
            <div className="flex items-center gap-3">
              <input type="radio" name="status"  onClick={()=>{
                setMode('delete');
              }}/>
              <label htmlFor="delete">Delete</label>
            </div>
          </section>
        </div>
        <div className="my-20 flex justify-center">
          <button
            type='button'
            className={`${ButtonType[loading ? 'light' : 'dark']} font-light text-base text-light font-gsans-light px-5 py-2 rounded-md px-5`}
            onClick={(e)=>{
              e.preventDefault();
              handleClick();
            }}
          >
            {loading ? "Loading..." : "Update"}
          </button>
        </div>
      </form>
    </SubLayout>
  );
};

export default EditAdmin;
